var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { color, height, position, themeGet, zIndex, maxWidth, overflow, minWidth, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { Heading } from '../../common/components/Text';
import { BASE_Z_INDEX, NEGATIVE_BASE_Z_INDEX, } from '../../common/constants/z-index';
import { boxSizing, wordBreak, flexShrink, } from '../../common/theme/system-utilities';
var StoererContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, boxSizing, maxWidth, minWidth, overflow, flexShrink);
StoererContainer.defaultProps = {
    pl: 'sp_4',
    pr: ['sp_12', 'sp_24', 'sp_32', 'sp_48'],
    position: ['relative', 'static'],
    alignItems: ['center'],
    boxSizing: 'border-box',
    flexWrap: 'nowrap',
    maxWidth: ['30%', '50%', 'none'],
    overflow: 'hidden',
    minWidth: '114px',
    flexShrink: 0, // Fix for IE11
};
var StoererText = styled(Heading)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::after {\n    ", ";\n\n    content: '';\n    position: absolute;\n    width: 100%;\n    top: 50%;\n    left: -", "px;\n    padding-right: ", "px;\n    padding-left: ", "px;\n    z-index: ", ";\n    background-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::after {\n    ", ";\n\n    content: '';\n    position: absolute;\n    width: 100%;\n    top: 50%;\n    left: -", "px;\n    padding-right: ", "px;\n    padding-left: ", "px;\n    z-index: ", ";\n    background-color: ", ";\n  }\n"])), color, position, zIndex, wordBreak, height, themeGet('space.sp_4'), themeGet('space.sp_4'), themeGet('space.sp_4'), NEGATIVE_BASE_Z_INDEX, function (_a) {
    var lineColor = _a.lineColor;
    return lineColor;
});
StoererText.defaultProps = __assign(__assign({}, Heading.defaultProps), { as: 'p', m: 'sp_0', mb: 'sp_0', position: 'relative', height: ['65%', '60%', '50%'], fontSize: ['fs_16', 'fs_20', null, 'fs_24'], lineHeight: ['lh_22', 'lh_24', null, 'lh_28'], zIndex: BASE_Z_INDEX, wordBreak: 'break-word', width: '100%' });
var GlobalRibbonGridItemLeft = function (_a) {
    var stoererText = _a.stoererText, stoererTextColor = _a.stoererTextColor, stoererLineColor = _a.stoererLineColor;
    if (!stoererText) {
        return null;
    }
    return (React.createElement(StoererContainer, null,
        React.createElement(StoererText, { "data-testid": "stoerer-text", color: stoererTextColor, lineColor: stoererLineColor }, stoererText)));
};
export default GlobalRibbonGridItemLeft;
var templateObject_1, templateObject_2;
